import { Link, navigate } from "gatsby";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker, CvvIcon } from "../../assets/svg";
import axios from "axios";
import Stripe from "stripe";
import { AiOutlineClose } from "react-icons/ai";
import { UserContext } from "../Context";
import { toast } from "react-toastify";
import { createOrder } from "../../utils/createOrder";
import confirmOrder from "../../utils/confirmOrder";

const stripe = new Stripe(`${process.env.STRIPE_SECRET_KEY}`, {
  apiVersion: "2022-11-15",
});

const MthYrInput = styled.div`
  background: #f8f8f8;
  width: 100%;
  height: 60px;
  border-radius: 10px;
  align-items: center;
  input {
    max-width: 652px;
    width: 100%;
    height: 100%;
    border: none;
    font-weight: 400;
    font-size: 14px;
    padding-left: 14px;
    background: transparent;
    &:focus {
      outline: 0px !important;
      box-shadow: none !important;
      background: #f8f8f8 !important;
    }
  }
`;
const Span = styled.span`
  background: transparent !important;
  border: none !important;
`;
const BillingDiv = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0125em;
  color: #676767;
  margin-top: 8px;
  margin-bottom: 16px;
`;
const Button = styled.button`
  max-width: 652px;
  width: 100%;
  height: 56px;
  background: #1da1f2;
  border-radius: 10px;
  outline: none;
  border: none;
  color: #ffffff;
`;
const Errormsg = styled.p`
  text-align: start !important;
  font-size: 12px !important;
  color: red !important;
`;
const CustomDatePickDiv = styled.div`
  background-color: white;
  padding: 0em 1.6em 0 1.6em;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  border: none;
  margin-right: 15px;
  font-weight: 400;
  align-items: center;
  font-size: 14px;
  padding-left: 14px;
  background: #f8f8f8;
  border-radius: 10px;
  cursor: pointer;
  @media (max-width: 575px) {
    margin-right: 6px;
  }
`;

const IFrame = styled.iframe`
  width: 600px;
  height: 60vh;
`;

const FrameDiv = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);

  span {
    position: absolute;
    top: 14%;
    right: 29%;
    cursor: pointer;
  }
`;

const PaymentForm = ({ state }: any) => {
  const { setResponse, selectCurrency } = useContext(UserContext) as any;
  const [loading, setLoading] = useState<boolean>(false);
  const [authModal, setAuthModal] = useState({
    url: "",
    intentId: "",
  });
  const [card, setCard] = useState({
    Cvv: "",
    amount: state.amount,
    country: "",
    dateInput: "",
    name: "",
    number: "",
    postalcode: "",
  });
  const [error, setError] = useState({
    Cvv: "",
    amount: state.amount,
    country: "",
    dateInput: "",
    name: "",
    number: "",
    postalcode: "",
  });


  const checkout = async (formData: any) => {
    setLoading(true);
    const { Cvv, country, dateInput, name, number, postalcode, amount } =
      formData;

    try {
      const paymentMethod = await stripe.paymentMethods.create({
        type: "card",
        card: {
          number: number,
          exp_month: Number(dateInput.split("/")[0]),
          exp_year: Number(dateInput.split("/")[1]),
          cvc: Cvv,
        },
      });
      const data = JSON.stringify({
        paymentMethod: `${paymentMethod.id}`,
        amount: Number(amount),
        email: state.email,
        name: name,
        postal_code: postalcode,
        country: country,
        currencyType: state.currencyType,
      });
      const config = {
        method: "post",
        url: `${process.env.BACKEND_URL}/api/checkout`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios(config);

      if (response.data.intent === undefined)
        throw {
          message: "Something Went Wrong, Please Try Again",
        };

      const { status, next_action, id } = response.data.intent;
      if (status === "succeeded") {
        setAuthModal({
          url: "",
          intentId: id,
        });
        handleConfirmation(response.data.intent.id);
      } else if (next_action) {
        setAuthModal({
          url: next_action.use_stripe_sdk.stripe_js,
          intentId: id,
        });
      }
    } catch (error: any) {
      setLoading(false);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmation = async (id: string) => {
    setAuthModal((old) => {
      return { ...old, url: "" };
    });

    try {
      const orderData = {
        paymentIntentId: id,
        link: state.links,
        serviceId: state.serviceId,
        quantity: state.quantity,
        type: state.type,
        userName: state.username,
        offer: false,
        email: state.email,
        amount: state.amount,
      } as any;

      const checkout = await confirmOrder(orderData);

      if (checkout) {
        const storeOrderData = {
          orderId: `${checkout.data.orderId}`,
          amount: `${selectCurrency} ${state.amount}`,
          post: state.links,
          type: `${state.type}`,
          quantity: `${state.quantity}`
        };
        createOrder(storeOrderData, state.username);
        setResponse({ storeOrderData, userName: state.username });
        navigate("/thankyou");
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };
  const expriy_format = (value: any) => {
    const expdate = value;
    const expDateFormatter =
      expdate.replace(/\//g, "").substring(0, 2) +
      (expdate.length > 2 ? "/" : "") +
      expdate.replace(/\//g, "").substring(2, 4);

    return expDateFormatter;
  };
  const handleChange = (e: any) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;

    setCard((old) => {
      if (!old) return old;
      return {
        ...old,
        [name]: value,
      };
    });
    setError((old) => {
      if (!old) return old;
      return {
        ...old,
        [name]: "",
      };
    });
  };
  const showError = (field: string, text: string) => {
    setError((old) => {
      if (!old) return old;
      return {
        ...old,
        [field]: text,
      };
    });
  };
  const handleSubmit = () => {
    if (card.name === "") {
      showError("name", "Please Enter Cardholder Name");
    } else if (card.number === "") {
      showError("number", "Please Enter Card Number");
    } else if (card.dateInput === "") {
      showError("dateInput", "Please Enter MM / YY");
    } else if (card.Cvv === "") {
      showError("Cvv", "Please Enter CVV");
    } else if (card.dateInput === "") {
      showError("dateInput", "Please Enter ExpiryDate");
    } else if (card.country === "") {
      showError("country", "Please Enter Billing Address ");
    } else if (card.postalcode === "") {
      showError("postalcode", "Please Enter Postal Code");
    } else {
      checkout(card);
    }
  };

  return (
    <form>

      <div className="mb-3">
        <MthYrInput className="input-group ">
          <input
            name="name"
            type="text"
            className="form-control"
            placeholder="cardholder name"
            onChange={handleChange}
          />
        </MthYrInput>
        <Errormsg>{error.name}</Errormsg>
      </div>
      <div className="mb-3">
        <MthYrInput className="input-group">
          <input
            name="number"
            type="text"
            className="form-control"
            placeholder="card number"
            maxLength={16}
            onChange={handleChange}
          />
        </MthYrInput>
        <Errormsg>{error.number}</Errormsg>
      </div>

      <div className="d-flex gap-3 mb-3">
        <MthYrInput className="input-group">
          <div className="d-flex h-100">
            <Span className="input-group-text" id="basic-addon1">
              <DatePicker />
            </Span>
            <input
              name="dateInput"
              type="text"
              className="cardetails-input"
              placeholder="MM / YY"
              onChange={handleChange}
              maxLength={5}
              value={expriy_format(card.dateInput)}
            />
          </div>
          <Errormsg>{error.dateInput}</Errormsg>
        </MthYrInput>
        <MthYrInput className="input-group  d-block">
          <div className="d-flex h-100">
            <Span className="input-group-text" id="basic-addon1">
              <CvvIcon />
            </Span>
            <input
              name="Cvv"
              type="text"
              className="form-control"
              placeholder="CVV"
              onChange={handleChange}
              maxLength={3}
            />
          </div>
          <Errormsg>{error.Cvv}</Errormsg>
        </MthYrInput>
      </div>
      <BillingDiv>Billing address</BillingDiv>
      <div className="d-flex gap-3 mb-3">
        <MthYrInput className="input-group d-block">
          <input
            name="country"
            type="text"
            className="form-control me-3 w-100"
            placeholder="India(IN)"
            onChange={handleChange}
          />
          <Errormsg>{error.country}</Errormsg>
        </MthYrInput>
        <MthYrInput className="input-group d-block">
          <input
            name="postalcode"
            type="text"
            className="form-control w-100"
            placeholder="postal code"
            onChange={handleChange}
          />
          <Errormsg>{error.postalcode}</Errormsg>
        </MthYrInput>
      </div>
      <p>
        By completing your order, you agree to the{" "}
        <Link to="">terms of services</Link> and{" "}
        <Link to="">privacy policy</Link>.
      </p>
      <p>
        All pricing is in USD. If paying with a different currency, the billed
        amount is subject to exchange rates and additional fees.
      </p>
      <div className="text-center">
        <Button className="my-3" type="button" onClick={handleSubmit}>
          {loading ? "Loading..." : `PAY ${selectCurrency}${state.amount}`}
        </Button>
      </div>


      {authModal.url !== "" ? (
        <FrameDiv>
          <IFrame src={authModal.url} title="myFrame"></IFrame>
          <span onClick={() => handleConfirmation(authModal.intentId)}>
            <AiOutlineClose size={40} />
          </span>
        </FrameDiv>
      ) : (
        " "
      )}
    </form>
  );
};

export default PaymentForm;
