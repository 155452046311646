import axios from "axios";
import { toast } from "react-toastify";
import { getUser } from "./getUser";


export const updateOrder = async (orderId: number, username: string) => {

  try {
    const { user, status } = await getUser(username) as any;

    
    if (status) {
      const relationaData = JSON.stringify({
        data: {
          orders: {
            connect: [orderId],
          },
        },
      });
      const config = {
        method: "put",
        url: `${process.env.BACKEND_URL}/api/user-lists/${user.id}?populate=orders`,
        headers: {
          "Content-Type": "application/json",
        },
        data: relationaData,
      };
      const {data} = await axios(config);  
      return data;
    }
  } catch (error: any) {
    toast.error(error.message);
  }
};
