import React from "react";
import Seo from "../components/Seo";
import PaymentForm from "../components/PaymentForm/PaymentForm";
import styled from "styled-components";
import PaymentNavbar from "../components/PaymentNavbar/PaymentNavbar";
interface CheckoutData {
  amount: number;
  currencyType: string;
  email: string;
  links: { url: string; displayUrl: string }[];
  postsData: { userName: string; latestPosts: any[], profilePicUrl: string };
  quantity: string;
  service: string;
  serviceId: number;
  totalQuantity: number;
  type: string;
  username: string;
}
const Section = styled.section`
  padding-top: calc(2rem + 70px);
  .isBackground {
    background-color: #f9fdff;
    padding-top: 4rem;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
  }
`;
const Checkout = ({ location: { state: state } }: { location: { state: { state: CheckoutData } } }) => {

  return (
    < >
      <Seo
        title="stagroo checkout page"
        description="stagroo checkout page description"
        canonical="/checkout"
        keywords="checkout"
      />
      <PaymentNavbar steps="ThirdStep" />
      < Section className="container">

        <div className="w-50 m-auto">

          {state && <PaymentForm state={state?.state} />}
        </div>
      </Section>
    </>
  );
};

export default Checkout;