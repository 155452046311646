import { Link, graphql, navigate, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import {
  Logo,
  CheckoutRightArrow,
  StepFillOne,
  StepFillTwo,
  StepFillFour,
} from "../../assets/svg";
import { paymentNavBarProps } from "../../interface";
import Data from "../../utils/data";

const Wrapper = styled.div`
  background-color: #f9fdff;
  padding: 24px 0;
  border: 0.5px solid #e3e3e3;
  @media (max-width: 575px) {
    padding: 5px 0;
  }
`;

const HeaderLogo = styled.div`
  @media (max-width: 575px) {
    svg {
      width: 98px;
    }
  }
`;
const Button = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #676767;
  position: absolute;
  left: 10%;
  a {
    text-decoration: none;
    color: #676767;
    :hover {
      color: #676767;
    }
  }
  @media (max-width: 575px) {
    left: 4%;
    line-height: 57px;
    font-size: 14px;
  }
`;
const ArrowSpan = styled.span`
  cursor: pointer;
  @media (max-width: 575px) {
    svg {
      height: 13px !important;
    }
  }
`;

const SvgElementOne = styled.div<{ steps?: string }>`
  position: relative;

  svg {
    g {
      stroke: ${(props) =>
        props.steps === "FirstStep" ? "#1da1f2" : "#d2d6dc"};
      path {
        fill: ${(props) =>
          props.steps === "FirstStep" ? "#1da1f2" : "transparent"};
      }
    }
  }
`;

const SvgElementTwo = styled.div<{ steps?: string }>`
  position: relative;

  svg {
    g {
      stroke: ${(props) =>
        props.steps === "SecondStep" ? "#1da1f2" : "#d2d6dc"};
      path {
        fill: ${(props) =>
          props.steps === "SecondStep" ? "#1da1f2" : "transparent"};
      }
    }
  }
`;

const SvgElementThree = styled.div<{ steps?: string }>`
  position: relative;
  svg {
    g {
      stroke: ${(props) =>
        props.steps === "ThirdStep" ? "#1da1f2" : "#d2d6dc"};
      path {
        fill: ${(props) =>
          props.steps === "ThirdStep" ? "#1da1f2" : "transparent"};
      }
    }
  }
`;

const SvgElementFour = styled.div<{ steps?: string }>`
  position: relative;

  svg {
    g {
      stroke: ${(props) => (props.steps === "Final" ? "#1da1f2" : "#d2d6dc")};
      path {
        fill: ${(props) =>
          props.steps === "Final" ? "#1da1f2" : "transparent"};
      }
    }
  }
`;

const SvgItemStyle = styled.div<{ steps?: string }>`
  position: absolute;
  top: 13%;
  .item-title-first {
    font-size: 14px;
    color: ${(props) => (props.steps === "FirstStep" ? "#ffffff" : "#11486a")};
    font-weight: 500;
  }
  .item-subtitle-first {
    font-size: 14px;
    color: ${(props) => (props.steps === "FirstStep" ? "#ffffff" : "#11486a")};
    font-weight: 400;
  }
  .item-title-second {
    font-size: 14px;
    color: ${(props) => (props.steps === "SecondStep" ? "#ffffff" : "#11486a")};
    font-weight: 500;
  }
  .item-subtitle-second {
    font-size: 14px;
    color: ${(props) => (props.steps === "SecondStep" ? "#ffffff" : "#11486a")};
    font-weight: 400;
  }
  .item-title-third {
    font-size: 14px;
    color: ${(props) => (props.steps === "ThirdStep" ? "#ffffff" : "#11486a")};
    font-weight: 500;
  }
  .item-subtitle-third {
    font-size: 14px;
    color: ${(props) => (props.steps === "ThirdStep" ? "#ffffff" : "#11486a")};
    font-weight: 400;
  }
  .item-title-fourth {
    font-size: 14px;
    color: ${(props) => (props.steps === "Final" ? "#ffffff" : "#11486a")};
    font-weight: 500;
  }
  .item-subtitle-fourth {
    font-size: 14px;
    color: ${(props) => (props.steps === "Final" ? "#ffffff" : "#11486a")};
    font-weight: 400;
  }
`;

const PaymentNavbar = ({ steps }: paymentNavBarProps) => {
  const { stepOne, stepTwo, stepThree, stepFour } = Data().strapiStepDetail;
  return (
    <div>
      <Wrapper>
        <div className="d-flex justify-content-between container">
          <div>
            <Button className="text-decoratioin-none">
              <a onClick={() => navigate(-1)}>
                <ArrowSpan className="pe-2">
                  <CheckoutRightArrow />
                </ArrowSpan>
              </a>
            </Button>
          </div>
          <Link to="/">
            <HeaderLogo>
              <Logo />
            </HeaderLogo>
          </Link>
        </div>
      </Wrapper>
      <div
        style={{ borderBottom: "0.5px solid #D6D6D6" }}
        className="my-0 overflow-hidden"
      >
        <div className="row d-flex justify-content-center ">
          <div
            className="d-flex flex-wrap my-4 justify-content-center"
            style={{ width: "65%" }}
          >
            <SvgElementOne
              className=" px-0 d-flex justify-content-center align-items-center mb-2 "
              steps={steps}
            >
              <SvgItemStyle className="d-flex flex-column" steps={steps}>
                <span className="mb-0 item-title-first ">{stepOne.title}</span>
                <span className="mb-0 item-subtitle-first">
                  {stepOne.description}
                </span>
              </SvgItemStyle>

              <StepFillOne />
            </SvgElementOne>

            <SvgElementTwo
              className=" px-0 d-flex justify-content-center mb-2"
              steps={steps}
            >
              <SvgItemStyle steps={steps}>
                <p className="mb-0 item-title-second mx-2 ">{stepTwo.title}</p>
                <p className="mb-0 item-subtitle-second mx-2">
                  {stepTwo.description}
                </p>
              </SvgItemStyle>
              <StepFillTwo />
            </SvgElementTwo>

            <SvgElementThree
              className=" px-0 d-flex justify-content-center mb-2"
              steps={steps}
            >
              <SvgItemStyle steps={steps}>
                <p className="mb-0 item-title-third  mx-2">{stepThree.title}</p>
                <p className="mb-0 item-subtitle-third mx-2">
                  {stepThree.description}
                </p>
              </SvgItemStyle>
              <StepFillTwo />
            </SvgElementThree>

            <SvgElementFour
              className="px-0 d-flex justify-content-center mb-2"
              steps={steps}
            >
              <SvgItemStyle steps={steps}>
                <p className="mb-0 item-title-fourth  mx-3">{stepFour.title}</p>
                <p className="mb-0 item-subtitle-fourth mx-3">
                  {stepFour.description}
                </p>
              </SvgItemStyle>
              <StepFillFour />
            </SvgElementFour>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentNavbar;