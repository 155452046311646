import axios from "axios";
import { toast } from "react-toastify";
import { updateOrder } from "./updateOrder";

interface Props {
  orderId: string;
  amount: string;
  post: [];
  type: string;
  quantity:string;
}
export const createOrder = async (storeOrderData: Props, username: string) => {

 
  try {
    const userData = JSON.stringify({
      data: {
        orderId: storeOrderData.orderId,
        amount: `${storeOrderData.amount}`,
        post: storeOrderData.post.map((item:any) => {
          return { postLinks: item?.url??"" };
        }),
        type:storeOrderData.type,
        quantity:`${storeOrderData.quantity}`
      },
    });

    const config = {
      method: "post",
      url: `${process.env.BACKEND_URL}/api/orders`,
      headers: {
        "Access-Control-Request-Headers": "*",
        "Content-Type": "application/json",
      },
      data: userData,
    };
    const { data } = await axios(config);
    if (data) {
  
      updateOrder(data.data.id, username);
    }
  } catch (error: any) {
    console.log("error",error)
    toast.error(error.message);
  }
};
