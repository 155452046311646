import axios from "axios";
import { toast } from "react-toastify";

export const getUser = async (userName: string) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.BACKEND_URL}/api/user-list/${userName}`,
      headers: {
        "Access-Control-Request-Headers": "*",
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios(config);
  
    if(data.status){
      const config2 = {
        method: "get",
        url: `${process.env.BACKEND_URL}/api/user-lists/${data.user.id}/?populate=orders`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      const value = await axios(config2);
      let userData = value.data.data.attributes;
      userData.id = data.user.id;
      return { status: true, user: userData };
    }else{
      return data
    }
  
  } catch (error: any) {
    toast.error(error.message);
  }
};
